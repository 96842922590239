import {renderToStaticMarkup} from "react-dom/server";
import {getCitationFilePath} from "../../api";

type HtmlParsedAnswer = {
    answerHtml: string;
    citations: string[];
};

export function parseAnswerToHtml(answer: string, isStreaming: boolean): HtmlParsedAnswer {
    const citations: string[] = [];

    // Trim whitespace and handle ongoing citation during streaming
    let trimmedAnswer = answer.trim();
    if (isStreaming) {
        trimmedAnswer = removeUnfinishedCitation(trimmedAnswer);
    }

    // Split text by citations enclosed in [ ]
    const parts = trimmedAnswer.split(/\[([^\]]+)\]/g);

    // Convert text and citations into HTML with Markdown support
    const answerHtml = parts
        .map((part, index) =>
            index % 2 === 0 ? convertMarkdownToHTML(part) : formatCitation(part, citations)
        )
        .join("");

    return { answerHtml, citations };
}

// Removes an incomplete citation at the end of a streaming answer
function removeUnfinishedCitation(text: string): string {
    const lastOpenBracket = text.lastIndexOf("[");
    const lastCloseBracket = text.lastIndexOf("]");

    return lastOpenBracket > lastCloseBracket ? text.substring(0, lastOpenBracket) : text;
}

// Converts citation text into a numbered <sup> link
function formatCitation(citationText: string, citations: string[]): string {
    let citationIndex = citations.indexOf(citationText);
    if (citationIndex === -1) {
        citationIndex = citations.push(citationText) - 1; // Store and get 1-based index
    }

    return renderToStaticMarkup(
        <a className="supContainer" title={citationText}>
            <sup>{citationIndex + 1}</sup>
        </a>
    );
}

// Converts simple Markdown syntax to HTML
function convertMarkdownToHTML(markdown: string): string {
    return markdown
        .replace(/#+ (.*?)(\n|$)/g, "<h4>$1</h4>") // Convert # Header
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")   // Convert **bold**
        .replace(/\*(.*?)\*/g, "<i>$1</i>")       // Convert *italic*
        .replace(/`([^`]+)`/g, "<code>$1</code>") // Convert `code`
        .replace(/\n/g, "<br>");                  // Convert new lines
}